<script setup lang="ts">
import SkipToMain from '~/components/layout/external/SkipToMain.vue';
import BackendError from '~/components/layout/errors/BackendError.vue';
import Header from '~/components/layout/internal/Header.vue';
import Footer from '~/components/layout/Footer.vue';
import CookiesAgreementBanner from '~/components/layout/CookiesAgreementBanner.vue';
import Notifications from '~/components/layout/internal/Notifications.vue';

provide('theme', 'dark');

onMounted(() => {
  document.body.classList.add('dark');
  document.body.classList.remove('light');

  document.body.classList.add('internal');
  document.body.classList.remove('external');
});
</script>

<template>
  <ClientOnly>
    <SkipToMain />

    <BackendError />

    <Header />

    <Notifications />

    <main id="main">
      <slot />
    </main>

    <Footer />

    <CookiesAgreementBanner />
  </ClientOnly>
</template>
